import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },

  {
    path: "/home",
    name: "/home",
    component: () => import("../views/Home.vue"),
  },

  {
    path: "/rule",
    name: "rule",
    component: () => import("../views/Gong.vue"),
    children: [
      {
        path: "/rule/01",
        name: "rule-01",
        meta: { title: "联盟基本信息" },
        component: () => import("../components/rule/01.vue"),
      },
      {
        path: "/rule/02",
        name: "rule-02",
        meta: { title: "联盟基本规定" },
        component: () => import("../components/rule/02.vue"),
      },
      {
        path: "/rule/03",
        name: "rule-03",
        meta: { title: "联盟刷怪规定" },
        component: () => import("../components/rule/03.vue"),
      },
      {
        path: "/rule/04",
        name: "rule-04",
        meta: { title: "联盟PAP/SRP制度" },
        component: () => import("../components/rule/04.vue"),
      },
      {
        path: "/rule/05",
        name: "rule-05",
        meta: { title: "联盟语音/QQ群规则" },
        component: () => import("../components/rule/05.vue"),
      },
      {
        path: "/rule/06",
        name: "rule-06",
        meta: { title: "视频录制规定" },
        component: () => import("../components/rule/06.vue"),
      },
      {
        path: "/rule/07",
        name: "rule-07",
        meta: { title: "直播规定" },
        component: () => import("../components/rule/07.vue"),
      },
      {
        path: "/rule/08",
        name: "rule-08",
        meta: { title: "龙焰集结/跟队规定" },
        component: () => import("../components/rule/08.vue"),
      },
      {
        path: "/rule/09",
        name: "rule-09",
        meta: { title: "龙焰月矿规定" },
        component: () => import("../components/rule/09.vue"),
      },
      {
        path: "/rule/10",
        name: "rule-10",
        meta: { title: "联盟建筑物规定" },
        component: () => import("../components/rule/10.vue"),
      },
      {
        path: "/rule/11",
        name: "rule-11",
        meta: { title: "公司考核标准" },
        component: () => import("../components/rule/11.vue"),
      },
    ],
  },
  {
    path: "/talk",
    name: "talk",
    component: () => import("../views/Gong.vue"),
    children: [
      {
        path: "/talk/shiwu",
        name: "shiwu",
        meta: { title: "联盟事务" },
        component: () => import("../components/talk/shiWu.vue"),
      },
      {
        path: "/talk/zcseat",
        name: "zcSeat",
        meta: { title: "联盟事务" },
        component: () => import("../components/talk/zcSeat.vue"),
      },
      {
        path: "/talk/zcqq",
        name: "zcQQ",
        meta: { title: "联盟事务" },
        component: () => import("../components/talk/zcQQ.vue"),
      },
      {
        path: "/talk/20240401",
        name: "202404-01",
        meta: { title: "联盟事务" },
        component: () => import("../components/talk/202404/202404-01.vue"),
      },
    ],
  },
  {
    path: "/tool",
    name: "tool",
    component: () => import("../views/Tool.vue"),
    children: [
      {
        path: "/tool/dark",
        name: "dark",
        meta: { title: "黑名单" },
        component: () => import("../components/dark/Dark.vue"),
      },
      {
        path: "/tool/tools",
        name: "tools",
        meta: { title: "常用工具" },
        component: () => import("../components/tools/Tools.vue"),
      },
      {
        path: "/tool/tools_math",
        name: "tools",
        meta: { title: "价格计算" },
        component: () => import("../components/tools/Tools_math.vue"),
      },
      {
        path: "/tool/tools_kuaidi",
        name: "tools",
        meta: { title: "快递计算" },
        component: () => import("../components/tools/Tools_kuaiDi.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  routes,
});

//当前页面跳转报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};
export default router;
